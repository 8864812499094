<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="6" lg="6" cols="12">
          <b-card
              no-body
              class="border-primary"
              v-if="aktarimInfo"
          >
            <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
              <h5 class="mb-0">
                Aktarım Bilgileri
              </h5>
              <b-badge variant="light-primary">
                #{{ aktarimInfo.aktarimID }}
              </b-badge>
              <small class="text-muted w-100">İşlem Tarihi : <strong>{{ aktarimInfo.kayitTarih.slice(0, 16) }}</strong></small>
            </b-card-header>
            <hr class="aktarim-detay-hr"/>
            <b-card-body class="p-2">
              <ul class="list-unstyled">
                <li>
                  <span class="align-middle">Aktarılan Ürün Sayısı: <strong>{{
                      aktarilanStoklar.length
                    }}</strong></span>
                </li>
                <li>
                  <span class="align-middle">İşlem Sahibi: <strong>{{ aktarimInfo.userName }}</strong></span>
                </li>
              </ul>
            </b-card-body>
          </b-card>
          <b-card
              no-body
              class="border-primary"
              v-if="aktarimInfo"
          >
            <b-row class="m-50">
              <b-col cols="12" class="pr-0">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="btn-icon float-right unPrint"
                    @click="cikartmaBelgesiPrint"
                >
                  <feather-icon icon="ArchiveIcon" class="mr-50"/>
                  Yazdır
                </b-button>
              </b-col>
            </b-row>
            <b-row class="px-2" id="cikartmaBelgesi" ref="cikartmaBelgesi">
              <b-col cols="12">
                <h6 class="text-left ml-1">
                  Aso {{ $store.getters.FIRMA_BILGILERI.unvan }}
                </h6>
                <h4 class="text-center">
                  İç Dağıtım - İsteme / Çıkartma Belgesi
                </h4>
              </b-col>
              <b-col cols="12">
                <h5 class="d-inline-block">İşletme : Depo Adı</h5>
                <h4 class="d-inline-block float-right text-danger font-family-math"> #{{ aktarimInfo.aktarimID }}</h4>
              </b-col>
              <b-col cols="12">
                <b-row class="mt-2">
                  <b-col cols="1" class="border">
                    <p class="mb-0 text-center font-weight-bolder">S.No:</p>
                  </b-col>
                  <b-col cols="4" class="border">
                    <p class="mb-0 text-center font-weight-bolder">Cinsi</p>
                  </b-col>
                  <b-col cols="3" class="border">
                    <p class="mb-0 text-center font-weight-bolder">Miktar</p>
                  </b-col>
                  <b-col cols="4" class="border">
                    <p class="mb-0 text-center font-weight-bolder">Birim</p>
                  </b-col>
                  <div class="d-content" v-for="(item,i) in aktarilanStoklar" :key="i">
                    <b-col cols="1" class="border">
                      <p class="mb-0 text-center">{{ i + 1 }}</p>
                    </b-col>
                    <b-col cols="4" class="border">
                      <p class="mb-0 text-center">{{ item.stokAdi }}</p>
                    </b-col>
                    <b-col cols="3" class="border">
                      <p class="mb-0 text-center">{{ item.adet }}</p>
                    </b-col>
                    <b-col cols="4" class="border">
                      <p class="mb-0 text-center">{{ item.birim }}</p>
                    </b-col>
                  </div>
                  <b-col cols="5" class="border">
                    <p class="mb-0 text-center font-weight-bolder">Toplam</p>
                  </b-col>
                  <b-col cols="3" class="border"/>
                  <b-col cols="4" class="border"/>
                  <b-col cols="4" class="border border-right-0 border-top-0 d-grid text-center p-2 pb-5">
                    <h5 class="mb-0">Teslim Anında Teslim Alan</h5>
                    <small>(Kimlik ve İmza)</small>
                  </b-col>
                  <b-col cols="4" class="border border-left-0 border-right-0 border-top-0 d-grid text-center p-2 pb-5">
                    <h5>Tarih: &nbsp;&nbsp;{{ currentDate }}</h5>
                    <b>Verilmesi Uygundur</b>
                    <p>Öğretmenevi Müdürü</p>
                  </b-col>
                  <b-col cols="4" class="border border-left-0 border-top-0 d-grid text-center p-2 pb-5">
                    <h5>Tarih: &nbsp;&nbsp;{{ currentDate }}</h5>
                    <b>Verildi</b>
                    <p class="mb-0">Kiler, Depo Memuru</p>
                    <small>(Kimlik ve İmza)</small>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col xl="6" lg="6" cols="12">
          <b-card-actions
              class="list-card"
              ref="first-card"
              @refresh="refreshStop('second-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Aktarılan Ürünler</h4>
                </b-col>
              </div>
            </b-row>
            <b-card
                v-if="aktarilanStoklar"
                no-body
                class="card-company-table mt-5"
            >
              <b-table
                  :items="aktarilanStoklar"
                  responsive
                  :fields="fields"
                  class="mb-0 aktarim-detay"
                  striped
                  hover
              >
                <template #cell(stokAdi)="data">
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="font-weight-bolder">
                        {{ data.item.stokAdi }}
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(adet)="data">
                  <div class="d-flex align-items-center">
                    <b-badge variant="primary">
                      <span>{{ data.item.adet }}</span>
                    </b-badge>
                  </div>
                </template>
                <template #cell(birim)="data">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25">{{ data.item.birim }}</span>
                  </div>
                </template>
                <template #cell(aktarimID)="data">
                  <b-button
                      variant="gradient-danger"
                      class="btn-icon rounded-circle"
                      @click="geriAktarimID = data.item.aktarimID, aktarilanUrunAdi = data.item.stokAdi,aktarilanAdetMax = data.item.adet,detayID = data.item.detayID"
                      size="sm"
                      v-b-modal.aktarim
                      v-b-tooltip.right="'İade'"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                  >
                    <font-awesome-icon icon="fa-solid fa-arrow-rotate-left"/>
                  </b-button>
                </template>
              </b-table>
            </b-card>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
        id="aktarim"
        ok-title="Geri Aktar"
        cancel-title="Vazgeç"
        modal-class="modal-primary"
        centered
        :title="`Geri Aktarım (${aktarilanUrunAdi})`"
        @ok="geriIade"
        @hidden="geriAktarimID = '', aktarilanUrunAdi = '', aktarilanAdet='',aktarilanAdetMax = '',detayID = ''"
    >
      <b-form-group
          :label="`Aktarılacak Ürün Adetini Seçiniz (max: ${aktarilanAdetMax})`"
          label-for="basicInput"
      >
        <b-form-input
            id="basicInput"
            type="number"
            maxlength="3"
            v-model="aktarilanAdet"
            autocomplete="off"
        />
      </b-form-group>
    </b-modal>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BSidebar,
  BTab,
  BTabs,
  BContainer,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vMultiselectListbox from 'vue-multiselect-listbox-v2'
import 'vue-multiselect-listbox-v2/dist/vue-multi-select-listbox.css'
import 'vue-multiselect-listbox-v2/src/scss/vue-multi-select-listbox.scss'
import _ from 'lodash'
import vSelect from 'vue-select'
import axios from 'axios'
import router from '@/router'

export default {
  components: {
    BSidebar,
    BCardBody,
    BCard,
    BTab,
    vSelect,
    BTabs,
    BContainer,
    vMultiselectListbox,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      detayID: '',
      aktarilanAdetMax: '',
      aktarilanAdet: '',
      geriAktarimID: '',
      aktarilanUrunAdi: '',
      id: this.$route.params.id,
      fields: [
        {
          key: 'stokAdi',
          label: 'Ürün Adı',
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'adet',
          label: 'Adet',
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'birim',
          label: 'Birim',
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'aktarimID',
          label: '',
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
      ],
    }
  },
  computed: {
    currentDate() {
      return localStorage.getItem('buroCurrentDate')
    },
    aktarimInfo() {
      return store.getters.AKTARIM_DETAY_GET.bilgiler
    },
    aktarilanStoklar() {
      return store.getters.AKTARIM_DETAY_GET.aktarilanStoklar
    },
  },
  methods: {
    cikartmaBelgesiPrint() {
      let printContents = document.getElementById('cikartmaBelgesi').innerHTML
      let originalContents = document.body.innerHTML
      let wrapperDiv = document.createElement('div')
      wrapperDiv.innerHTML = printContents
      wrapperDiv.style.padding = '50px'
      document.body.innerHTML = wrapperDiv.outerHTML
      window.print()
      document.body.innerHTML = originalContents
      location.reload()
    },
    geriIade() {
      store.dispatch('aktarimDetayIade', {
        aktarimID: this.geriAktarimID,
        aktarilanAdet: this.aktarilanAdet,
        detayID: this.detayID,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status === 200) {
              store.dispatch('aktarimDetay', this.id)
              this.geriAktarimID = ''
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              store.dispatch('aktarimDetay', this.id)
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            store.dispatch('aktarimDetay', this.id)
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
  created() {
    store.dispatch('aktarimDetay', this.id)
  },
  beforeDestroy() {
    store.commit('DEPO_DETAY_STOK_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
@media print {
  #cikartmaBelgesi {
    @page {
      size: A5 !important;
    }
    width: 8cm;
    height: 5cm;
  }
}

.aktarim-detay .table thead th, table tbody td {
  padding: 0.4rem !important;
}

.aktarim-detay .b-card-header {
  display: inline-block !important;
}
</style>
<style scoped>
.d-content {
  display: contents;
}

#sidebar-form {
  padding: 5%;
}

.search-input {
  border-color: #c3baba !important;
}

.search-bar {
  width: 20% !important;
}

.aktarim-detay-hr {
  margin: 0.6rem !important;
}

.font-family-math {
  font-family: math;
}

</style>
